import React, { FunctionComponent } from 'react';
import clsx from 'clsx';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import itemStyles from '../components/item/cases.module.css';
import styles from './heypractice.module.css';
import Layout from '../components/item/layout';
import { meta } from '../../../metaData';
import Team from '../components/team';
import Blockquote from '../components/blockquote';
import DemoVideo from '../components/demo-video';
import Video from '../../layout/video';

const HeyPractice: FunctionComponent = () => {
  const data = useStaticQuery(graphql`
    query {
      david: file(relativePath: { eq: "heypractice/david.png" }) {
        childImageSharp {
          fluid(maxWidth: 215, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      syam: file(relativePath: { eq: "heypractice/syam.png" }) {
        childImageSharp {
          fluid(maxWidth: 215, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ai: file(relativePath: { eq: "heypractice/ai.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      macbook: file(relativePath: { eq: "heypractice/macbook.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen: file(relativePath: { eq: "heypractice/screen.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screens: file(relativePath: { eq: "heypractice/screens.png" }) {
        childImageSharp {
          fluid(maxWidth: 1190, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ear: file(relativePath: { eq: "heypractice/ear.png" }) {
        childImageSharp {
          fluid(maxWidth: 1190, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ergo: file(relativePath: { eq: "heypractice/ergo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1190, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lensing: file(relativePath: { eq: "heypractice/lensing.png" }) {
        childImageSharp {
          fluid(maxWidth: 1190, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mockups: file(relativePath: { eq: "heypractice/mockups.png" }) {
        childImageSharp {
          fluid(maxWidth: 1190, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen2: file(relativePath: { eq: "heypractice/screen2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1190, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screens2: file(relativePath: { eq: "heypractice/screens2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1190, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen3: file(relativePath: { eq: "heypractice/screen3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1190, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen4: file(relativePath: { eq: "heypractice/screen4.png" }) {
        childImageSharp {
          fluid(maxWidth: 1190, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      full: file(relativePath: { eq: "heypractice/full.png" }) {
        childImageSharp {
          fluid(maxWidth: 1190, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen5: file(relativePath: { eq: "heypractice/screen5.png" }) {
        childImageSharp {
          fluid(maxWidth: 1190, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      full2: file(relativePath: { eq: "heypractice/full2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1190, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      coverWithBtn: file(relativePath: { eq: "heypractice/hey-practice-with-btn.png" }) {
        childImageSharp {
          fluid(maxWidth: 1190, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      cover: file(relativePath: { eq: "heypractice/hey-practice-cover.png" }) {
        childImageSharp {
          fluid(maxWidth: 1190, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      video_cover: file(relativePath: { eq: "heypractice/cover_heyPractice.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout
      score={['EdTech', 'Germany', '2021', 'Ongoing']}
      navBtn="#14181A"
      className={styles.header}
      contentClassName={styles.content}
      headerTitle="AI-based Interactive Learning Platform for Sales and Marketing"
      subTitle="How Brocoders helped to build an interactive digital learning platform and integrate AI-based units to create an exclusive training tool for one of the fast-growing EdTechs in Europe."
      metaData={meta.heypractice}
    >
      <DemoVideo source="Laptop" poster={data.video_cover.childImageSharp.fluid.srcWebp} />
      <div className={clsx(itemStyles.inner, styles.pb36)}>
        <h2 className={itemStyles.title}>Our client</h2>
        <p className={itemStyles.paragraph}>
          In October 2021, David Neuendorf, CEO the European EdTech company - HeyPractice (ex
          PeterPitch), contacted us with a request to re-build MVP of their interactive learning
          platform. HeyPractice is a VC-backed SaaS product with headquarters in Germany. The story
          behind its idea is that David used to work in a marketing agency and therefore knew that
          sales skills must be constantly practiced and honed. Combining the innovative approach of
          artificial intelligence with best practices and recommendations for sales pitches, he
          aimed to create a platform to help you learn this online. Now he would like to re-build
          MVP using modern technical stack.
        </p>
      </div>

      <section className={clsx('section', styles.gray)}>
        <div className="inner">
          <Blockquote
            img={data.david.childImageSharp.fluid}
            isRating
            blockquoteClassName={styles.blockquote}
            innerClassName={styles.blockquoteInner}
            linkText="Read full review on Clutch"
            downloadReviewLink="https://clutch.co/profile/brocoders?_gl=1%2Aqphzju%2A_ga%2AMTg5ODQwMjc4Ny4xNjQ5NjY3MTY4%2A_ga_D0WFGX8X3V%2AMTY1MDQ1MzQzOC45MS4xLjE2NTA0NTM0NDQuNTQ.#review-1987582"
            text={`“With Brocoders, we were able to set up a highly skilled IT team in the shortest amount of time. For finding a team with similar skills we would have needed 4-6 months instead of the instant start.”`}
            author={
              <React.Fragment>
                <strong>David Neuendorf</strong> <br />
                CEO,{' '}
                <a href="https://heypractice.com/" target="_blank" className={styles.red}>
                  HeyPractice.com
                </a>
              </React.Fragment>
            }
          />
        </div>
      </section>

      <section className="section">
        <div className={itemStyles.inner}>
          <div className={styles.founders}>
            <div className={styles.founder}>
              <Img
                fluid={data.david.childImageSharp.fluid}
                className={styles.founderAvatar}
                alt="david's photo"
              />
              <div className={styles.founderTexts}>
                <div className={styles.founderName}>
                  <strong>David Neuendorf</strong>
                </div>
                <div className={styles.founderPosition}>CEO & Founder at HeyPractice.com</div>
                <div className={styles.founderBio}>
                  In July 2020, David started as Founder & CEO at Peter Pitch. From the April of
                  2022 - CEO & Founder at HeyPractice.com
                </div>
              </div>
            </div>
            <div className={styles.founder}>
              <Img
                fluid={data.syam.childImageSharp.fluid}
                className={styles.founderAvatar}
                alt="founder's Avatar"
              />
              <div className={styles.founderTexts}>
                <div className={styles.founderName}>
                  <strong>Syam Sadasivan Pilla</strong>
                </div>
                <div className={styles.founderPosition}>CTO at Peter Pitch / HeyPractice.com</div>
                <div className={styles.founderBio}>Started in January 2022</div>
              </div>
            </div>
          </div>
        </div>
        <div className={itemStyles.inner}>
          <h2 className={itemStyles.title}>Business idea</h2>
          <p className={itemStyles.paragraph}>
            The project is a training course for sales represantatives, managers and other
            employees. It is an excellent tool that allows staff to practice pitching skills for
            efficiency. Furthermore, it is a chance to transform companies’ learning culture itself.
          </p>
          <p className={itemStyles.paragraph}>
            Thus, their target audience is represented by companies that have departments for staff
            training and development. What makes it unique is the practice-oriented approach, where
            you learn theory and immediately put it into practice. The training takes place in an
            interactive form. As you go through the course, you record yourself and your pitches
            while the AI analyzes your responses. AI visually and aurally identifies your strengths
            and weaknesses and gives automatic feedback.
          </p>
          <p className={itemStyles.paragraph}>
            It offers the product as a stand-alone solution or as a solution that provides
            compatibility with an existing LMS. With HeyPractice you can train digitally or in
            person. And also, both group training and interactive training at your workplace can be
            applied.
          </p>
          <h3 className={clsx(itemStyles.subtitle, styles.pb32)}>
            The German approach to security guarantees
          </h3>
          <p className={clsx(itemStyles.paragraph, itemStyles.prebullet)}>
            The level of data protection in Heypractice meets the highest industry standards:
          </p>
          <ul className={clsx(itemStyles.bullets, itemStyles.red)}>
            <li>
              data is stored in data centers certified in accordance with PCI DSS Level 1 and ISO
              9001/ISO 27001;
            </li>
            <li>has an SSL certificate and works exclusively through secure connections;</li>
            <li>complies with the GDPR.</li>
          </ul>
        </div>

        <div className={itemStyles.inner}>
          <h2 className={itemStyles.title}>The situation</h2>
          <p className={itemStyles.paragraph}>
            David Neuendorf, CEO of PeterPitch, wanted to design an interactive digital learning
            tool with empowerment options.
          </p>
          <p className={itemStyles.paragraph}>
            He already had a MVP of a product and was looking for an IT company to relaunch and
            improve their software. As a VC-backed company, PeterPitch needed a skilled team to
            build a new version of product in a short terms, within 4-5 months.
          </p>
          <p className={itemStyles.paragraph}>
            In this regard, they were interested in qualified specialists with experience in
            tech-stack, such as ReactJS, NextJS, and RoR. David looked at 4-5 companies and ended up
            partnering with Brocoders.
          </p>
        </div>

        <div className={clsx(itemStyles.inner, styles.pb32)}>
          <h2 className={itemStyles.title}>Working process</h2>
          <Video
            poster={data.cover.childImageSharp.fluid.srcWebp}
            posterWithPlayBtn={data.coverWithBtn.childImageSharp.fluid.srcWebp}
            video="hey_practice"
          />
          <h3 className={clsx(itemStyles.subtitle, styles.pb32)}>Request</h3>
          <p className={itemStyles.paragraph}>
            Initially, the customer has already performed the development of MVP backend, and they
            came to us to either redo it or make it from scratch. The client also had a ready-made
            design for new advanced functionality for their software solution. Thus, at the time of
            contacting us, the main request was that to rewrite the frontend interface and add new
            features.
          </p>

          <h3 className={clsx(itemStyles.subtitle, styles.pb32)}>Product audit</h3>
          <p className={clsx(itemStyles.paragraph, itemStyles.prebullet)}>
            Following our usual approach to effective development, we had to understand if the
            backend was built properly so we can use it for the new frontend. That's why we
            conducted a technical audit and gave recommendations on updating the MVP and where to
            start the process. We provided the Heypractice team with a technical conclusion and a
            justification for why it would be better to rewrite the backend of a product:
          </p>
          <ul className={clsx(itemStyles.bullets, styles.pb32, itemStyles.red)}>
            <li>there was no test coverage for the backend part;</li>
            <li>there was no documentation or readme file for the project running;</li>
            <li>code quality was average;</li>
            <li>there was no dividing into front and back sides;</li>
            <li>all application logic implemented via controllers;</li>
            <li>API was not built on REST principles.</li>
          </ul>
          <p className={itemStyles.paragraph}>
            That being said, we also summed up that during future development of the new product
            version, we could reuse the old database structure (taking models and migrations from
            the current application). It was a very important conclusion because time restrictions
            weren't allowed to rebuild the backend from scratch.
          </p>
          <p className={clsx(itemStyles.paragraph, itemStyles.prebullet)}>
            Further, our team provided the client with the following solutions on how to improve and
            increase the quality of the application:
          </p>
          <ul className={clsx(itemStyles.bullets, itemStyles.red)}>
            <li>adding test coverage, at least for business logic;</li>
            <li>
              dividing API will allow several developers to develop in parallel and make it possible
              to use the API for mobile applications. It also makes the project scalable;
            </li>
            <li>
              separate logic into different layers to improve maintainability and testability;
            </li>
            <li>make stress testing to check the system operates under a lot of use and load;</li>
            <li>add OpenApi to document the backend interface;</li>
            <li>consider AWS for hosting to use CI/CD services.</li>
          </ul>
          <p className={itemStyles.paragraph}>
            We suggested looking at some AWS services, as all our projects are hosted on AWS. So we
            can provide complete expertise about it. Another good point to consider is that they
            offer some internal services for running and storing videos. But alternatively, we could
            store videos on an AWS s3 bucket, make a preview, and resize them with ruby.
          </p>
          <p className={itemStyles.paragraph}>
            David agreed that the project needed to be rewritten, and we started our cooperation.
          </p>
        </div>
      </section>

      <section className={clsx('section', styles.gray)}>
        <div className="inner">
          <Blockquote
            img={data.david.childImageSharp.fluid}
            blockquoteClassName={styles.blockquote}
            innerClassName={styles.blockquoteInner}
            text={`“We saw a huge amount of time that we saved. For finding a team with similar skills we would have needed 4-6 month instead of the instant start.”`}
            author={
              <React.Fragment>
                <strong>David Neuendorf</strong> <br />
                CEO,{' '}
                <a href="https://heypractice.com/" target="_blank" className={styles.red}>
                  HeyPractice.com
                </a>
              </React.Fragment>
            }
          />
        </div>
      </section>

      <div className={clsx(itemStyles.inner)}>
        <h3 className={clsx(itemStyles.subtitle, styles.pb32)}>Working stages</h3>
        <p className={itemStyles.paragraph}>
          Therefore, we put together a team with great experience and set up a development process
          using best agile practices.
        </p>

        <p className={clsx(itemStyles.paragraph, itemStyles.prebullet)}>
          In general, the workflow of the project took place in two phases:
        </p>
        <ul className={clsx(itemStyles.bullets, itemStyles.red)}>
          <li>
            MVP development: until March 2022, Brocoders developers released the solution into
            production.
          </li>
          <li>Final development: until August 2022, our team finalized the MVP.</li>
        </ul>

        <p className={itemStyles.paragraph}>
          At the first stage of the workflow, our team worked in cooperation with the customer’s
          developer, who wrote the first version of the backend.
        </p>
        <p className={itemStyles.paragraph}>
          And in the second phase, we had the pleasure of collaborating with Syam, CTO at Peter
          Pitch. He joined the team in January 2022 and participated in frontend and backend
          development.
        </p>
        <p className={itemStyles.paragraph}>
          For communication and task tracking, we use the tools of Slack, Jira, and Email.
        </p>
        <p className={itemStyles.paragraph}>
          Our developers were really encouraged during the working process. Searching for suitable
          solutions, they not only carried out integration but also conducted thorough research on
          AI products and managed communications with their representatives.
        </p>
        <p className={itemStyles.paragraph}>
          This was an essential part of the job, as the training courses require users to record
          their pitches on audio or video. AI-powered speech recognition makes each pitch
          automatically transcribed and scored. After loading the pitch, the employee receives
          personal feedback on the assignment without waiting for manual input from the coach, which
          makes the learning process more efficient.
        </p>

        <p className={clsx(itemStyles.paragraph, itemStyles.prebullet)}>
          Basically, we have carried out three AI integrations to provide comprehensive feedback for
          user input:
        </p>
        <ul className={clsx(itemStyles.bullets, itemStyles.red)}>
          <li>
            voice recognition and transcript of audio and video records into text for further
            analysis;
          </li>
          <li>text analysis for allowed or forbidden words;</li>
          <li>recognition of emotional color by face image.</li>
        </ul>
        <p className={itemStyles.paragraph}>Thanks to AI units, we make soft skills measurable.</p>
      </div>

      <section className="section">
        <div className={itemStyles.inner}>
          <Img className={styles.aiImg} fluid={data.ai.childImageSharp.fluid} alt="AI units" />
          <h3 className={clsx(itemStyles.subtitle, styles.pb32)}>
            What did we do? Our technologies
          </h3>

          <p className={clsx(itemStyles.paragraph, itemStyles.prebullet)}>
            Our team developed the main functionality of the software product with additional
            AI-based features. The process consisted of the following:
          </p>
          <ul className={clsx(itemStyles.bullets, itemStyles.red, styles.pb36)}>
            <li>Сreating and managing infrastructure based on AWS services;</li>
            <li>Setting up CI/CD pipeline;</li>
            <li>Building frontend and backend applications.</li>
          </ul>

          <p className={clsx(itemStyles.paragraph, itemStyles.prebullet)}>
            We developed the product using tech-stack:
          </p>
          <ul className={clsx(itemStyles.bullets, itemStyles.red)}>
            <li>NodeJS for backend part;</li>
            <li>ReactJS for frontend pard;</li>
            <li>
              AWS services:
              <ul className={clsx(itemStyles.bullets, itemStyles.red)}>
                <li>
                  <a
                    target="_blank"
                    href="https://aws.amazon.com/ecr/?nc1=h_ls"
                    className={styles.red}
                  >
                    Amazon Elastic Container Registry (Amazon ECR){' '}
                  </a>
                  - to store, share, and deploy container software.
                </li>
                <li>
                  <a target="_blank" href="https://aws.amazon.com/ecs/" className={styles.red}>
                    Amazon Elastic Container Service (Amazon ECS){' '}
                  </a>
                  - allows running highly secure and scalable containers.
                </li>
                <li>
                  <a target="_blank" href="https://aws.amazon.com/s3/" className={styles.red}>
                    Amazon S3{' '}
                  </a>
                  - object storage to retrieve any amount of data from anywhere.
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://aws.amazon.com/cloudwatch/"
                    className={styles.red}
                  >
                    Amazon CloudWatch{' '}
                  </a>
                  - to observe and monitor AWS applications and resources in the cloud and
                  on-premises.
                </li>
                <li>
                  <a target="_blank" href="https://aws.amazon.com/rds/" className={styles.red}>
                    Amazon RDS{' '}
                  </a>
                  - to set up, operate, and scale a relational database in the cloud.
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://aws.amazon.com/cloudformation/?nc1=h_ls"
                    className={styles.red}
                  >
                    AWS CloudFormation{' '}
                  </a>
                  - accelerate cloud provisioning with Infrastructure as code.
                </li>
                <li>
                  <a target="_blank" href="https://aws.amazon.com/cdk/" className={styles.red}>
                    AWS CDK{' '}
                  </a>
                  - to define cloud application resources using familiar programming languages
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://aws.amazon.com/sqs/?nc1=h_ls"
                    className={styles.red}
                  >
                    Amazon SQS{' '}
                  </a>
                  - managed message queue for microservices, distributed systems and serverless
                  applications.
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://aws.amazon.com/route53/?nc1=h_ls"
                    className={styles.red}
                  >
                    Amazon Route 53{' '}
                  </a>
                  - the way to route end users to Internet applications.
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://aws.amazon.com/secrets-manager/?nc1=h_ls"
                    className={styles.red}
                  >
                    AWS Secrets Manager{' '}
                  </a>
                  - for credential password management.
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </section>

      <section className="section">
        <div className={itemStyles.inner}>
          <h2 className={itemStyles.title}>Our team</h2>
          <p className={itemStyles.paragraph}>
            For this project, Brocoders has assembled a team of professionals with extensive
            backgrounds. In this way, we save clients time in finding highly qualified professionals
            suitable for the project.
          </p>
          <p className={itemStyles.paragraph}>
            The customer characterizes Brocoders team as "fast set-up, very communicative, highly
            skilled, most flexible when talking about finding a good solution for all parties."
            David Neuendorf, CEO & Founder at Heypractice
          </p>
          <p className={itemStyles.paragraph}>So the Brocoders team included:</p>
          <Team
            color="#129AFE"
            list={[
              { count: 1, title: 'Project\nmanager' },
              { count: 3, title: 'Frontend\ndevelopers' },
              { count: 2, title: 'Backend\ndevelopers' },
              { count: 1, title: 'Fullstack\ndeveloper' },
              { count: 1, title: 'Quality Assurance\nspecialist' },
            ]}
          />
          <p className={itemStyles.paragraph}>
            <div>
              At the start one frontend developer and one backend developer started working in
              October 2021. They started the initialization of the project, set up the development
              environment, and the initial project configuration (linter, test runner, etc). In a
              week, two more frontend developers joined the team.
            </div>
            <div>
              Our team has a good level of communication inside, which helps them quickly come to
              the optimal solution. Frontend and backend constantly communicated directly with each
              other and could pick up each other's tasks if necessary.
            </div>
          </p>
          <p className={itemStyles.paragraph}>
            New people joined the project as needed and when new tasks appeared in the project.
            Thus, in December, a QA specialist came to the team, and a month later, another frontend
            developer joined.
          </p>
        </div>
      </section>

      <section className="section">
        <div className={itemStyles.inner}>
          <h2 className={itemStyles.title}>Key challenges</h2>
          <h3 className={clsx(itemStyles.subtitle, styles.pb32)}>Searching for AI solutions</h3>
          <p className={itemStyles.paragraph}>
            One of the key tasks for Brocoders team was to integrate the AI solution. The bottom
            line is that you can train in the area you want and get immediate feedback from the AI.
            You don't have to wait for the coach to process your results. You simply record yourself
            on video or audio during the training process and get a quick assessment from the AI.
          </p>
          <p className={itemStyles.paragraph}>
            Therefore, our developers were looking for a solution that could recognize voices and
            convert audio recordings to text so that AI could analyze them.
          </p>
          <p className={clsx(itemStyles.paragraph, itemStyles.prebullet)}>
            The AI gave feedback to the user based on given parameters, such as:
          </p>
          <ul className={clsx(itemStyles.bullets, itemStyles.red)}>
            <li>permitted and prohibited words;</li>
            <li>length of pauses;</li>
            <li>volume of sound;</li>
            <li>speed of speech (spoken words per minute).</li>
          </ul>
          <p className={clsx(itemStyles.paragraph, itemStyles.pb96)}>
            <div>
              Voice recognition parameters such as volume criteria, length of pauses, and speech of
              speech have been set on the backend.
            </div>
            <div>
              To analyze the text for allowed and forbidden words, the client can fill in a list of
              do and don't words when creating a task for the user.
            </div>
          </p>
        </div>
      </section>
      <Img
        fluid={data.macbook.childImageSharp.fluid}
        className={clsx(styles.mb80, styles.fullImg)}
        alt="macbook"
      />

      <section className="section">
        <div className={itemStyles.inner}>
          <p className={itemStyles.paragraph}>
            So, our developers were tasked with integrating an AI service that can be used for voice
            recognition and converting audio files into text transcription. To accomplish this, they
            had several options.
          </p>
          <p className={itemStyles.paragraph}>
            First, we turned to the{' '}
            <a target="_blank" className={styles.red} href="https://www.assemblyai.com/">
              Assembly AI
            </a>{' '}
            provider, which had a very competitive pricing model. But it turned out that their
            service does not support the German language. However, during communication with our
            team, the Assembly AI support promised to add the German language. We did the
            integration, and the release date came, but still, there was no ready-made Assembly AI
            solution for the German language. As a result, we had to abandon this variant.
          </p>
          <p className={itemStyles.paragraph}>
            Therefore, we switched to a solution based on the{' '}
            <a
              href="https://www.ibm.com/cloud/watson-speech-to-text"
              target="_blank"
              className={styles.red}
            >
              IBM Watson
            </a>{' '}
            platform. This option was chosen in cooperation with CTO and was good value for money.
            The main challenge in working with this provider was that they did not have JavaScript
            documentation. Therefore, the developers spent a lot of time figuring out how to
            integrate this system. However, they could cope with the task thanks to their
            persistence, experience and professionalism.
          </p>
          <p className={itemStyles.paragraph}>
            We integrate{' '}
            <a href="https://precire.ai/" target="_blank" className={styles.red}>
              PRECIRE AI
            </a>{' '}
            solutions to generate psychological results of text analysis.
          </p>

          <Img fluid={data.screen.childImageSharp.fluid} alt="" />

          <h3 className={clsx(itemStyles.subtitle, styles.pb32)}>SCORM standards implementation</h3>
          <p className={itemStyles.paragraph}>
            Our team did a bunch of research and found a solution that makes course content
            compatible with{' '}
            <a href="https://scorm.com/" target="_blank" className={styles.red}>
              SCORM{' '}
            </a>
            standards so that enterprise clients can play our content in their LMS. Thus, we found
            and integrated
            <a href="https://rusticisoftware.com/" target="_blank" className={styles.red}>
              {' '}
              Rustici Software
            </a>
            . Why was it so important?
          </p>
          <p className={itemStyles.paragraph}>
            The fact is that often companies interested in e-learning services for their employees
            have their own LMS (e.g., Moodle or eLearning). For such corporate clients, it is
            crucial that their employees do not switch to another portal during training but remain
            in their LMS. And if we want them to be able to upload our training content into their
            LMS, the courses must be compiled with the unified SCORM.
          </p>
          <p className={itemStyles.paragraph}>
            Sharable Content Object Reference Model (SCORM) is a set of technical standards for
            e-learning products that helps them play together. Advanced Distributed Learning
            Initiative (ADL) developed it to support the Department of Defense in standardizing
            learning objects and making learning portable across systems. SCORM consists of
            sub-specifications. Content packaging defines how the content needs to be packaged and
            described. Run-Time specifies how the content must be launched, how the data interacts
            with the LMS and the data model requirements for this interaction. Finally, sequencing
            determines how the learner can move between parts of the course (SCO). Compliance with
            this standard ensures that the content is compatible with the LMS.
          </p>
          <p className={itemStyles.paragraph}>
            Thus, we needed to find a solution that would provide an additional opportunity to
            export courses to other LMS. Rustici Software converts content according to SCORM
            standards.
          </p>
          <p className={itemStyles.paragraph}>
            It is also essential that thanks to the Rustici Software solution, users do not feel
            they have switched to another platform when entering their credentials. We, for our
            part, do not receive user data.
          </p>

          <h3 className={clsx(itemStyles.subtitle, styles.pb32)}>Admin panel implementation</h3>
          <p className={itemStyles.paragraph}>
            To manage, control and monitoring all the product processes, the team needed to find the
            best solution for implementing the admin panel.
          </p>
          <p className={clsx(itemStyles.paragraph, itemStyles.prebullet)}>
            There were several options for discussion:
          </p>
          <ul className={clsx(itemStyles.bullets, itemStyles.red)}>
            <li>move the functionality of the admin panel into a separate application;</li>
            <li>built it into our solution, but to differentiate access by roles.</li>
          </ul>
          <p className={itemStyles.paragraph}>
            We still settled on an intermediate option, when we collect two separate folders for
            client pages and admin panel from one shared Front database.
          </p>
        </div>
      </section>

      <div className={styles.almostFullImgWrapper}>
        <Img fluid={data.screens.childImageSharp.fluid} className={styles.fullImg} alt="" />
      </div>

      <section className="section">
        <div className={itemStyles.inner}>
          <h3 className={clsx(itemStyles.subtitle, styles.pb32)}>Delivered solution</h3>
          <p className={itemStyles.paragraph}>
            The solution we performed is an EdTech SaaS product based on AI. It is available in two
            languages: German and English.
          </p>
          <p className={clsx(itemStyles.paragraph, itemStyles.prebullet)}>
            As HeyPractice continues to grow, they have plans to develop the platform further:
          </p>
          <ul className={clsx(itemStyles.bullets, itemStyles.red)}>
            <li>backoffice for coaches;</li>
            <li>community for company portal;</li>
            <li>integration with Slack, Microsoft Teams.</li>
          </ul>
          <p className={clsx(itemStyles.paragraph, itemStyles.prebullet)}>
            HeyPractice customers who already used digital training on the platforms:
          </p>

          <div className={styles.logos}>
            <a
              href="https://www.mysecondear.de/"
              target="_blank"
              className={clsx(styles.logo, styles.earlogo)}
            >
              <Img fluid={data.ear.childImageSharp.fluid} alt="link to ear" />
            </a>
            <a
              href="https://www.ergo.com/"
              target="_blank"
              className={clsx(styles.logo, styles.logoergo)}
            >
              <Img fluid={data.ergo.childImageSharp.fluid} alt="link to ergo" />
            </a>
            <a href="https://www.lensingmedia.de/" target="_blank" className={styles.logo}>
              <Img fluid={data.lensing.childImageSharp.fluid} alt="link to lensingmedia" />
            </a>
          </div>
          <p className={clsx(itemStyles.paragraph, styles.pb36)}>
            A mobile version of the product is also developed.
          </p>
          <Img
            fluid={data.mockups.childImageSharp.fluid}
            className={styles.fullImg}
            alt="mockups picture"
          />
        </div>
      </section>

      <section className="section">
        <div className={itemStyles.inner}>
          <h2 className={itemStyles.title}>Application structure</h2>
          <h3 className={clsx(itemStyles.subtitle, styles.pb32)}>Company portal</h3>
          <ul className={clsx(itemStyles.bullets, itemStyles.red)}>
            <li>
              <p className={itemStyles.paragraph}>
                <div className={styles.liName}>
                  <strong>Dashboard</strong>
                </div>
                <div>
                  The admin can track performance data on the company portal through an intuitive
                  dashboard. Pages of Overview and Environment updates are available here. It is
                  easy to check the overview of both teams' and members' activities. And here you
                  can freely look through your materials, feedback, polls, and new records.
                </div>
              </p>
            </li>
            <li>
              <p className={itemStyles.paragraph}>
                <div className={styles.liName}>
                  <strong>Courses</strong>
                </div>
                <div>
                  This section displays a list of teams and individual participants, as well as the
                  pitches of coaches. Here you can also set up the schedule for the courses.
                </div>
              </p>
            </li>
            <li>
              <p className={itemStyles.paragraph}>
                <div className={styles.liName}>
                  <strong>Teams</strong>
                </div>
                <div>
                  In the company portal, it is also possible to create and edit Teams. There you may
                  add members, monitor the team progress and time sessions, see the team leadership
                  board and criteria overview. You may look through the courses and records of the
                  group's members and easily find information about the results of each employee.
                </div>
              </p>
            </li>
          </ul>
          <Img fluid={data.screen2.childImageSharp.fluid} alt="" />
          <ul className={clsx(itemStyles.bullets, itemStyles.red)}>
            <li>
              <p className={itemStyles.paragraph}>
                <div className={styles.liName}>
                  <strong>Content</strong>
                </div>
                <div>
                  Here you may add and organize content materials. Folders, files and lists with
                  different materials for courses or with videos or presentations are placed here.
                </div>
              </p>
            </li>
            <li>
              <p className={itemStyles.paragraph}>
                <div className={styles.liName}>
                  <strong>Workspace</strong>
                </div>
                <div>
                  This is the workspace where you manage the members. Here you can find user data,
                  their emails, and their roles. Also, there are invoices for payment for purchased
                  products. Mind that payments are made offline.
                </div>
              </p>
            </li>
          </ul>
        </div>
      </section>

      <div className={styles.almostFullImgWrapper}>
        <Img fluid={data.screens2.childImageSharp.fluid} className={styles.fullImg} alt="" />
      </div>

      <section className="section">
        <div className={itemStyles.inner}>
          <h3 className={clsx(itemStyles.subtitle, styles.pb32)}>Employee portal</h3>
          <ul className={clsx(itemStyles.bullets, itemStyles.red)}>
            <li>
              <p className={itemStyles.paragraph}>
                <div className={styles.liName}>
                  <strong>Profile</strong>
                </div>
                <div>
                  This is the space where users can customize their profile: change photos, set up
                  notifications, and so on.
                </div>
              </p>
            </li>
            <li>
              <p className={itemStyles.paragraph}>
                <div className={styles.liName}>
                  <strong>Dashboard</strong>
                </div>
                <div>
                  Thanks to the dashboard, an employee can monitor his progress and environment
                  updates. You can keep track of the courses you subscribe to.
                </div>
              </p>
            </li>
            <li>
              <p className={itemStyles.paragraph}>
                <div className={styles.liName}>
                  <strong>Courses</strong>
                </div>
                <div>
                  Here is a list of the courses you are taking. You can track progress, view
                  available pitches, check out the pitch leaderboard, and track task scores. You see
                  task info and reviews: feedback, comments and AI-Feedback.
                </div>
              </p>
            </li>
          </ul>

          <Img fluid={data.screen3.childImageSharp.fluid} className={styles.fullImg} alt="" />

          <ul className={clsx(itemStyles.bullets, itemStyles.red)}>
            <li>
              <p className={itemStyles.paragraph}>
                <div className={styles.liName}>
                  <strong>Community</strong>
                </div>
                <div>
                  This is a communication space where you can not only follow the updates and news
                  of other team members but also receive valuable materials from the coach. It was
                  not included in the MVP.
                </div>
              </p>
            </li>
          </ul>
          <Img fluid={data.screen4.childImageSharp.fluid} className={styles.fullImg} alt="" />

          <h3 className={clsx(itemStyles.subtitle, styles.pb32)}>Superadmin - backoffice</h3>
          <ul className={clsx(itemStyles.bullets, itemStyles.red)}>
            <li>
              <p className={itemStyles.paragraph}>
                <div className={styles.liName}>
                  <strong>Companies</strong>
                </div>
                <div>
                  Here we add and edit information about:
                  <ul className={clsx(itemStyles.bullets, itemStyles.red)}>
                    <li>
                      companies, their names, number of employees, number of courses, contact person
                      (who may have the super admin role);
                    </li>
                    <li>
                      all users, enter their personal data and specify the roles: super admin, admin
                      and member;
                    </li>
                    <li>teams;</li>
                    <li>courses;</li>
                    <li>content (folders, files, lists);</li>
                    <li>
                      workspace; here, we indicate to whom the company gives permission to manage
                      team and user data, view team statistics, create, edit and delete courses and
                      pitches etc.
                    </li>
                  </ul>
                </div>
              </p>
            </li>
            <li>
              <p className={itemStyles.paragraph}>
                <div className={styles.liName}>
                  <strong>Content</strong>
                </div>
                <div>
                  What you can do here:
                  <ul className={clsx(itemStyles.bullets, itemStyles.red)}>
                    <li>upload files;</li>
                    <li>
                      create lists of reactions, do-words, dont-words and attach them to companies;
                    </li>
                    <li>add content, form folders, upload files and lists.</li>
                  </ul>
                </div>
              </p>
            </li>
            <li>
              <p className={itemStyles.paragraph}>
                <div className={styles.liName}>
                  <strong>Courses</strong>
                </div>
                <div>
                  This is where you add a new course. Teams and participants are determined for each
                  course, and there is a space for viewing pitches and making new ones.
                </div>
              </p>
            </li>
          </ul>
        </div>
      </section>

      <section className="section">
        <div className={itemStyles.inner}>
          <h2 className={itemStyles.title}>Courses. How it works</h2>
          <p className={itemStyles.paragraph}>
            The application has a really user-friendly interface. Corporate clients can either buy
            ready-made HeyPractice courses or develop them on their own based on our platform with
            the help of their coaches (for example, add their tasks, etc.).
          </p>
          <h3 className={clsx(itemStyles.subtitle, styles.pb32)}>Opportunities for trainees</h3>
          <p className={itemStyles.paragraph}>
            For salespeople and employees, HeyPractice offers digital products to train sales pitch.
            For example, it can be a strategic selling technique developed by coaches. The aim is to
            train how to create a successful Sales Strategy.
          </p>
          <p className={clsx(itemStyles.paragraph, itemStyles.prebullet)}>
            How you can improve your skills:
          </p>
          <ul className={clsx(itemStyles.bullets, itemStyles.red)}>
            <li>Pass the theoretical part at the beginning of the course.</li>
            <li>Take interactive multiple-choice tests to test gained knowledge.</li>
            <li>Take Quizzes, write short texts, and record audio tracks.</li>
            <li>
              <div>
                Start recording videos of your own pitches. For example, you may practice how to
                offer a new investment digital product. Your pitch is evaluated with some criteria:
              </div>
              <ul className={clsx(itemStyles.bullets, itemStyles.red)}>
                <li>persuasiveness (confident body language, speaking/writing clearly);</li>
                <li>charisma (communicating dynamically);</li>
                <li>quickness (without pause of more than 5 seconds);</li>
                <li>uniqueness (unique techniques and solving problems.)</li>
              </ul>
            </li>
            <li>
              Get immediate AI-Feedback. When you complete the task, results are processed by AI
              units, and you see AI-Analytics and examine what you need to improve.
            </li>
          </ul>
        </div>
      </section>

      <Img
        fluid={data.full.childImageSharp.fluid}
        className={clsx(styles.pb32, styles.fullImg)}
        alt=""
      />

      <section className="section">
        <div className={itemStyles.inner}>
          <h3 className={clsx(itemStyles.subtitle, styles.pb32)}>How to create a course</h3>
          <p className={itemStyles.paragraph}>
            When a corporate client buys a company product, a workspace is created for them. Then
            the admin invites people and assigns user roles to managers and employees.
          </p>
          <p className={clsx(itemStyles.paragraph, itemStyles.prebullet)}>
            In the company portal, he may use editor flow and can customize the task preview:
          </p>
          <ul className={clsx(itemStyles.bullets, itemStyles.red)}>
            <li>Upload materials and documents.</li>
            <li>
              <div>Create content:</div>
              <ul className={clsx(itemStyles.bullets, itemStyles.red)}>
                <li>add video, text, images or audio;</li>
                <li>set timer;</li>
                <li>
                  set evaluation criteria, like problem-solving, leadership, body language,
                  teamwork, communication etc.
                </li>
              </ul>
            </li>
            <li>
              Choose the type of task response. Thus, here you pose the task and let participants
              record video responses or audio replies, write a short text, and create a quiz task.
              You also provide here a knowledge slide and import existing pitches or presentations.
            </li>
            <li>
              Install pitch objectives. Here you set hidden objectives (video, text, images) and
              playbook (issues that may arise, like lack of budget, busy client, competitor), as
              well as evaluation criteria.
            </li>
            <li>
              <div>
                Customize AI-Feedback. It allows setting up automatic feedback directly after the
                pitch task. It includes:
              </div>
              <ul className={clsx(itemStyles.bullets, itemStyles.red)}>
                <li>spoken words per minute;</li>
                <li>volume and clearness;</li>
                <li>a number of used do-words and dont-words.</li>
              </ul>
            </li>
          </ul>
          <Img fluid={data.screen5.childImageSharp.fluid} className={styles.fullImg} alt="" />
          <p className={itemStyles.paragraph}>
            You also may customize quiz previews. There you put a question, its description, and set
            quiz type (multiple choice or open question).
          </p>
        </div>
      </section>

      <section className="section">
        <div className={clsx(styles.pb32, itemStyles.inner)}>
          <h2 className={itemStyles.title}>The results</h2>
          <p className={itemStyles.paragraph}>
            HeyPractice is indeed an example of a successful startup and already has something to be
            proud of. The company showed an excellent level of efficiency: 100% ROI in four months.
          </p>
          <p className={itemStyles.paragraph}>
            With an intuitive and playful learning environment, employee satisfaction increased by
            25%. By implementing innovative interactive methods, the company has cut employee
            onboarding time in half. Thanks to this, it was possible to shorten the training cycle
            for new employees.
          </p>
          <p className={itemStyles.paragraph}>
            To sum up, being a HeyPractice partner is really motivating. We get the opportunity to
            contribute to developing an innovative learning platform. And this cooperation is
            fruitful for both parties.
          </p>
        </div>
      </section>

      <section className={clsx('section', styles.gray)}>
        <div className="inner">
          <Blockquote
            img={data.david.childImageSharp.fluid}
            isRating
            blockquoteClassName={styles.blockquote}
            innerClassName={styles.blockquoteInner}
            text={`“Thanks to the expertise of Brocoders, the company is able to save time identifying highly-qualified individuals that perfectly fit the project. The team is highly communicative and internal stakeholders are particularly impressed with their adaptability and solutions-based approach.”`}
            author={
              <React.Fragment>
                <strong>David Neuendorf</strong> <br />
                CEO and Founder of HeyPractice
              </React.Fragment>
            }
          />
        </div>
      </section>

      <Img fluid={data.full2.childImageSharp.fluid} className={styles.fullImg} alt="" />
</Layout>
  );
};

export default HeyPractice;
